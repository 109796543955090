import SharedFunctions from './shared_functions';

$(document).ready(function () {

    $paymentPlanQuoteForm.submit(function () {

        let form_valid = true;
        let message = '';
        let email = $paymentPlanQuoteForm.find("input#payment_plan_step1_email").val();
        let $postcode = $paymentPlanQuoteForm.find("input#payment_plan_step1_postcode");

        // Validate email
        let email_check = SharedFunctions.form_email_valid(email, form_valid, message);
        form_valid = email_check[0];
        message = email_check[1];

        // Validate the postcode field
        let postcode_check = SharedFunctions.form_postcode_valid($postcode, form_valid, message);
        form_valid = postcode_check[0];
        message = postcode_check[1];

        if (!form_valid) {
            $paymentPlanQuoteFormToast.append('<div class="toast-header bg-danger text-white"><strong class="mr-auto">Error</strong><small class="text-white">Just now</small><button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close"><span aria-hidden="true">&times;</span></button></div><div class="toast-body bg-white"><ul>' + message + '</ul></div></div>');
            $paymentPlanQuoteFormToast.toast('show');
            $paymentPlanQuoteFormToast.on('hidden.bs.toast', function () {
                $paymentPlanQuoteFormToast.html('');
            });
        }

        return form_valid;
    });

    $("#payment_plan_step1_email").on("focusout", function () {
        let $containing_div = $(".pp-dont-send-me-checkbox");
        let $checkbox = $("#payment_plan_step1_optOutEmail");
        let $skipInput = $('#payment_plan_step1_skip_opt_out_email');
        let email = $(this).val();

        SharedFunctions.form_display_gdpr_checkbox(email, $checkbox, $containing_div, $skipInput);
    });
});
