import Regex from './regex';

const SharedFunctions = {
    // Pass this a string value for the postcode and it will return the postcode nicely uppercase with space, false if invalid
    validatePostcodeFormField(pCode) {
        let postCode = pCode.toUpperCase();

        // Permitted letters depend upon their position in the postcode.
        let alpha1 = "[abcdefghijklmnoprstuwyz]";                       // Character 1
        let alpha2 = "[abcdefghklmnopqrstuvwxy]";                       // Character 2
        let alpha3 = "[abcdefghjkpmnrstuvwxy]";                         // Character 3
        let alpha4 = "[abehmnprvwxy]";                                  // Character 4
        let alpha5 = "[abdefghjlnpqrstuwxyz]";                          // Character 5
        let BFPOa5 = "[abdefghjlnpqrst]";                               // BFPO alpha5
        let BFPOa6 = "[abdefghjlnpqrstuwzyz]";                          // BFPO alpha6
        let pcexp = [];
        let valid = false;

        pcexp.push(new RegExp("^(bf1)(\\s*)([0-6]{1}" + BFPOa5 + "{1}" + BFPOa6 + "{1})$", "i"));
        pcexp.push(new RegExp("^(" + alpha1 + "{1}" + alpha2 + "?[0-9]{1,2})(\\s*)([0-9]{1}" + alpha5 + "{2})$", "i"));
        pcexp.push(new RegExp("^(" + alpha1 + "{" +
            "1}[0-9]{1}" + alpha3 + "{1})(\\s*)([0-9]{1}" + alpha5 + "{2})$", "i"));
        pcexp.push(new RegExp("^(" + alpha1 + "{1}" + alpha2 + "{1}" + "?[0-9]{1}" + alpha4 + "{1})(\\s*)([0-9]{1}" + alpha5 + "{2})$", "i"));
        pcexp.push(/^(GIR)(\s*)(0AA)$/i);
        pcexp.push(/^(bfpo)(\s*)([0-9]{1,4})$/i);
        pcexp.push(/^(bfpo)(\s*)(c\/o\s*[0-9]{1,3})$/i);

        let i;
        for (i = 0; i < pcexp.length; i++) {
            if (pcexp[i].test(postCode)) {
                pcexp[i].exec(postCode);
                postCode = RegExp.$1.toUpperCase() + " " + RegExp.$3.toUpperCase();
                postCode = postCode.replace(/C\/O\s*/, "c/o ");
                valid = true;
                break;
            }
        }

        // Return with either the reformatted valid postcode or the original invalid postcode
        if (!valid) {
            return false;
        } else {
            return postCode;
        }
    },

    // Validate the email field in a form, pass in the email, if the form is current valid, current message, return array with if the form is still valid and any additional messages
    form_email_valid(email, form_valid, message) {
        if (!Regex.email.test(email)) {
            form_valid = false;
            let error_message = 'Please enter a valid email';
            message += '<li>' + error_message + '</li>';
        } else {
            if (email.length === 0) {
                form_valid = false;
                let error_message = 'Please enter a valid email';
                message += '<li>' + error_message + '</li>';
            }
        }
        return [form_valid, message]
    },

    // Validate the postcode field in a form, pass in the postcode object, if the form is current valid, current message, return array with if the form is still valid and any additional messages
    form_postcode_valid($postcode, form_valid, combined_message) {

        // Nicely format the postcode value
        let postcode_val = SharedFunctions.validatePostcodeFormField($postcode.val());
        let error_message = '';

        if (!postcode_val) {
            form_valid = false;
            error_message = 'Please enter a valid postcode';
            combined_message += '<li>' + error_message + '</li>';
        } else if (!Regex.postcode.test(postcode_val)) {
            form_valid = false;
            error_message = 'Please enter a valid postcode';
            combined_message += '<li>' + error_message + '</li>';
        } else {
            // apply formatted postcode to the form field
            $postcode.val(postcode_val);
        }
        return [form_valid, combined_message, error_message];
    },

    form_display_gdpr_checkbox(email, $checkbox, $containing_div, $skipInput) {
        let email_test = Regex.email.test(email);

        if (email_test) {
            $.ajax({
                type: 'POST',
                url: '/uk/ajax/gdpr_details',
                data: {
                    'email': email,
                }
            }).done(function (result) {

                let data = result.message.details;

                if (data.checkbox_checked) {
                    $checkbox.prop('checked', true);
                } else {
                    $checkbox.prop('checked', false);
                }

                if (data.show) {
                    $containing_div.removeClass('d-none');
                    $skipInput.val('0');
                } else {
                    $containing_div.addClass('d-none');
                    $skipInput.val('1');
                }
            });
        } else {
            if ($containing_div.hasClass('d-none')) {
                $containing_div.removeClass('d-none');
            }
        }
    },

    form_check_required_tanker_type(email) {
        let email_test = Regex.email.test(email);

        if (email_test) {
            $.ajax({
                type: 'GET',
                url: '/uk/ajax/required_tanker_type',
                data: {
                    'email': email,
                }
            }).done(function (result) {

                let requiredTanker = result.message.tankerType;

                let $optionStandard = $('select[name=theTanker] option[value=standard]');
                let $optionTanker4 = $('select[name=theTanker] option[value="4wheel"]');
                let $optionTankerBaby = $('select[name=theTanker] option[value=baby]');
                if (requiredTanker === 'tanker4') {
                    $optionStandard.hide().prop('disabled', true);
                    $optionTankerBaby.hide().prop('disabled', true);
                    $optionTanker4.show().prop('disabled', false).prop('selected', true);
                } else if (requiredTanker === 'tankerBaby') {
                    $optionStandard.hide().prop('disabled', true);
                    $optionTanker4.hide().prop('disabled', true);
                    $optionTankerBaby.show().prop('disabled', false).prop('selected', true);
                } else {
                    $optionStandard.show().prop('disabled', false);
                    $optionTanker4.show().prop('disabled', false);
                    $optionTankerBaby.show().prop('disabled', false);
                }
                // mark check field as checked
                $("#required_tanker_check").val('1');
            });
        }
    },

    // Validates the phone number based off the landline and mobile phone regex, if matches one then all good!
    form_uk_phone_number_valid(phone_number) {
        let valid = true;

        let landline_test = Regex.uk_landline_number.test(phone_number);
        let mobile_test = Regex.uk_mobile_number.test(phone_number);

        if (!landline_test && !mobile_test) {
            valid = false;
        } else {
            if (phone_number.length === 0) {
                valid = false;
            }
        }

        return valid;
    },

    // Commercial order - company name validation
    commercialOrderCompanyName(applyErrorToField = true, $companyName, bootstrapErrorClass) {
        let valid = true;

        if ($companyName.is(":visible")) {
            if ($companyName.val().length < 2) {
                valid = false;

                if (applyErrorToField) {
                    $companyName.addClass(bootstrapErrorClass);
                    $companyName.parent().find('.error-message').html('Please provide us with a company name');
                }
            } else {
                $companyName.parent().find('.error-message').html('');
                $companyName.removeClass(bootstrapErrorClass);
            }
        } else {
            $companyName.parent().find('.error-message').html('');
            $companyName.removeClass(bootstrapErrorClass);
        }

        return valid;
    },

    // Commercial order - Vat number
    commercialOrderVatNumber(applyErrorToField = true, $vatNumber, $vatNotRegistered, bootstrapErrorClass) {
        let valid = true;

        if ($vatNumber.is(":visible")) {
            if ($vatNumber.val().length < 8 && $vatNotRegistered.prop('checked') !== true) {
                valid = false;

                if (applyErrorToField) {
                    $vatNumber.addClass(bootstrapErrorClass);
                    $vatNumber.parent().find('.error-message').html('Please provide us with your company VAT number');
                }
            } else {
                $vatNumber.parent().find('.error-message').html('');
                $vatNumber.removeClass(bootstrapErrorClass);
            }
        } else {
            $vatNumber.parent().find('.error-message').html('');
            $vatNumber.removeClass(bootstrapErrorClass);
        }

        return valid;
    },

    // Commercial order - RDCO use code
    commercialOrderRdcoUseCode(applyErrorToField = true, $rdcoUseCode, bootstrapErrorClass) {
        let valid = true;

        if ($rdcoUseCode.is(":visible")) {
            if ($rdcoUseCode.val() === '') {
                valid = false;

                if (applyErrorToField) {
                    $rdcoUseCode.addClass(bootstrapErrorClass);
                    $rdcoUseCode.parent().find('.error-message').html('Please provide us with your RDCO use code');
                }
            } else {
                $rdcoUseCode.parent().find('.error-message').html('');
                $rdcoUseCode.removeClass(bootstrapErrorClass);
            }
        } else {
            $rdcoUseCode.parent().find('.error-message').html('');
            $rdcoUseCode.removeClass(bootstrapErrorClass);
        }

        return valid;
    },

    // Commercial order - RDCO number
    commercialOrderRdcoNumber(applyErrorToField = true, $rdcoNumber, bootstrapErrorClass) {
        let valid = true;

        if ($rdcoNumber.is(":visible")) {
            if ($rdcoNumber.val() === '') {
                valid = false;

                if (applyErrorToField) {
                    $rdcoNumber.addClass(bootstrapErrorClass);
                    $rdcoNumber.parent().find('.error-message').html('Please provide us with your company VAT number');
                }
            } else {
                $rdcoNumber.parent().find('.error-message').html('');
                $rdcoNumber.removeClass(bootstrapErrorClass);
            }
        } else {
            $rdcoNumber.parent().find('.error-message').html('');
            $rdcoNumber.removeClass(bootstrapErrorClass);
        }

        return valid;
    }

};

export default SharedFunctions;
